import { useEffect } from "react";

const useTaskOnboardingCreate = ({ step }) => {
  useEffect(() => {
    //reset last styles
    const prev_highlight_element = document.getElementById("file_uploader");
    prev_highlight_element.style.zIndex = 1;
    prev_highlight_element.style.backgroundColor = "transparent";

    const handleResize = () => {
      const tooltip_element = document.getElementsByClassName(
        "task-onboarding__tooltip"
      )?.[0];
      const pointer_element = document.getElementsByClassName(
        "task-onboarding__tooltip__pointer"
      )?.[0];

      const highlight_element = document.getElementById("create_task_button");
      const hightlight_position = highlight_element?.getBoundingClientRect();

      const hightlight_position_x = hightlight_position?.left;
      const hightlight_position_y = hightlight_position?.top;
      const hightlight_width = hightlight_position?.width;
      highlight_element.style.zIndex = 1000;

      tooltip_element.style.flexDirection = "column-reverse";
      tooltip_element.style.alignItems = "flex-end";
      pointer_element.style.marginRight = "70px";

      const pointer_position_top =
        hightlight_position_y - tooltip_element?.offsetHeight;
      const pointer_position_left =
        hightlight_position_x - hightlight_width - hightlight_width / 2 - 20;

      tooltip_element.style.top = `${pointer_position_top}px`;
      tooltip_element.style.left = `${pointer_position_left}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [step]);

  return null;
};

export default useTaskOnboardingCreate;
