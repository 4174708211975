import "./styles.scss";

import classnames from "classnames";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Tooltip from "components/ToolTip";
import Switch from "components/form/Switch";
import FormikMobileRadio from "components/formik/FormikMobileRadio";

import plans_data from "libs/plans_data";
import priceRender from "libs/helpers/priceRender";
import useResponsive from "libs/helpers/useResponsive";

import { useApp } from "context/app";
import { useNewWebsite } from "context/client/new_website";

import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";

const mainClass = "new-website-content__choose-plan__subscription-plan";

const SubscriptionPlan = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const { plans_list } = useApp();
  const { website, mobile_step } = useNewWebsite();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const plans = plans_list?.map((item, index) => ({
    ...plans_data?.[index],
    ...item,
  }));

  return (
    <div className={mainClass}>
      {isMobile && mobile_step === 1 && (
        <div className={`${mainClass}__header`}>
          <div className={`${mainClass}__header__item`}>
            <h3>{!isMobile ? t("Subscription plan") : "Payment"}</h3>
          </div>
          {!!isMobile && mobile_step === 1 ? (
            <FormikMobileRadio />
          ) : (
            <div className={`${mainClass}__header__item`}>
              <div className={`${mainClass}__header__item__billed-switcher`}>
                <span
                  className={classnames({
                    active: values?.billed_type === "monthly",
                  })}
                >
                  <label>{t("Monthly")}</label>
                  <small>{t("billed monthly")}</small>
                </span>
                <Switch
                  checked={values?.billed_type === "yearly"}
                  onChange={() =>
                    setFieldValue(
                      "billed_type",
                      values?.billed_type === "monthly" ? "yearly" : "monthly"
                    )
                  }
                />
                <span
                  className={classnames({
                    active: values?.billed_type === "yearly",
                  })}
                >
                  <label>{t("Yearly")}</label>
                  <small>{t("billed monthly")}</small>
                </span>
              </div>
            </div>
          )}
          <div className={`${mainClass}__header__item`} />
        </div>
      )}
      {(!isMobile || mobile_step === 2) && (
        <div
          className={classnames(`${mainClass}__content`, {
            [`${mainClass}__content--error`]: errors?.plan && !!touched?.plan,
          })}
        >
          {plans?.map(({ icon, name, description, prices, features }) => {
            const current_price = prices?.find(
              (item) => item?.contract_period === values?.billed_type
            );

            const formatted_price = (current_price?.price?.amount / 100) * 0.8;
            const decimalPart = formatted_price.toFixed(2).split(".")?.[1];

            return (
              <button
                key={current_price?.id}
                type="button"
                onClick={() => setFieldValue("plan", current_price?.id)}
                className={classnames(`${mainClass}__content__item`, {
                  [`${mainClass}__content__item--active`]:
                    values?.plan === current_price?.id,
                  [`${mainClass}__content__item--current`]:
                    website?.subscription?.price_id === current_price?.id,
                })}
              >
                {icon}
                <span>{`${name} plan`}</span>
                <p>{t(description)}</p>
                <small className={`${mainClass}__content__item__promo-info`}>
                  <strike>
                    {priceRender(
                      current_price?.price?.amount / 100,
                      current_price?.price?.currency
                    )}
                  </strike>{" "}
                  - {t("lowest price in last 30 days")}
                </small>
                <div className={`${mainClass}__content__item__price`}>
                  <h1>
                    {current_price?.price?.currency !== "pln" &&
                      priceRender("", current_price?.price?.currency)}
                    {((current_price?.price?.amount / 100) * 0.8)?.toFixed(0)}
                    <span style={{ marginRight: 5 }}>.{decimalPart}</span>
                    {current_price?.price?.currency === "pln" &&
                      priceRender("", current_price?.price?.currency)}
                  </h1>
                  <div className={`${mainClass}__content__item__price__info`}>
                    <span>{t("per Month")}</span>
                    <span>{t("with tax")}</span>
                  </div>
                </div>
                <div className={`${mainClass}__content__item__features`}>
                  {features?.map(({ icon, label, value, hint }, index) => (
                    <Tooltip
                      key={index}
                      text={t(hint)}
                      position="center"
                      size="medium"
                    >
                      <div
                        className={`${mainClass}__content__item__features__item`}
                      >
                        <label>
                          {icon}
                          {`${t(label)}:`}
                        </label>
                        <span>{`${value} / ${t("month")}`}</span>
                      </div>
                    </Tooltip>
                  ))}
                </div>
                {website?.subscription?.price_id === current_price?.id && (
                  <div className={`${mainClass}__content__item__current`}>
                    <span>{t("Current plan")}</span>
                  </div>
                )}
                <Button
                  size="small"
                  variant="transparent"
                  icon={<ExternalLinkIcon />}
                  onClick={(e) => e.stopPropagation}
                  external_href
                  href={t("more_details_link")}
                >
                  {t("More details")}
                </Button>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlan;
