import { useEffect } from "react";
import { waitForTheElement } from "wait-for-the-element";

const useTaskOnboardingFinish = ({ step }) => {
  useEffect(() => {
    const handleResize = async () => {
      await waitForTheElement(".table__row", {
        timeout: 5000,
      });

      const tooltip_content_element = document.getElementsByClassName(
        "task-onboarding__tooltip"
      )?.[0];
      const tooltip_element = document.getElementsByClassName(
        "task-onboarding__tooltip__content"
      )?.[0];
      const pointer_element = document.getElementsByClassName(
        "task-onboarding__tooltip__pointer"
      )?.[0];

      let highlight_element =
        document.getElementsByClassName("table__row")?.[1];
      while (!!!highlight_element) {
        highlight_element = document.getElementsByClassName("table__row")?.[1];
      }

      highlight_element.style.zIndex = 1000;
      highlight_element.style.position = "relative";
      highlight_element.style.backgroundColor = `#F7F9FA`;

      tooltip_content_element.style.flexDirection = "column-reverse";
      tooltip_element.style.width = "1000px";
      tooltip_element.style.maxWidth = "1000px";

      pointer_element.style.marginRight = "0px";

      const hightlight_position = highlight_element?.getBoundingClientRect();
      const hightlight_position_y = hightlight_position?.top;

      const pointer_position_top =
        hightlight_position_y - tooltip_content_element?.offsetHeight;
      const pointer_position_left =
        window.innerWidth / 2 - tooltip_content_element?.offsetWidth / 2;

      tooltip_content_element.style.top = `${pointer_position_top}px`;
      tooltip_content_element.style.left = `${pointer_position_left}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [step]);

  return null;
};

export default useTaskOnboardingFinish;
