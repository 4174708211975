import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import DetailsCard from "components/DetailsCard";
import DetailsCardList from "components/DetailsCardList";

import { useApp } from "context/app";
import { useNewWebsite } from "context/client/new_website";

import priceRender from "libs/helpers/priceRender";
import useResponsive from "libs/helpers/useResponsive";
import getCountryFromCode from "libs/helpers/getCountryFromCode";

const mainClass = "new-website-content__check-your-data";

const CheckYourData = () => {
  const { t } = useTranslation();

  const {
    website,
    form_data,
    change_plan_mode,
    prevStep,
    setStep,
    submitForm,
    setMobileStep,
  } = useNewWebsite();
  const { isMobile } = useResponsive();

  const [loading, setLoading] = useState(false);
  const { plans_list } = useApp();

  let current_plan = null;
  plans_list.forEach((item) => {
    item?.prices?.forEach((price) => {
      if (price.id === website?.subscription?.price_id) {
        current_plan = { ...item, ...price };
      }
    });
  });

  let selected_plan = null;
  plans_list.forEach((item) => {
    item?.prices?.forEach((price) => {
      if (price.id === form_data?.plan) {
        selected_plan = { ...item, ...price };
      }
    });
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      await submitForm();
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const details_data = [
    {
      title: t("Overview"),
      action: {
        label: t("Edit"),
        onclick: () => {
          setStep(0);
          setMobileStep(0);
        },
      },
      items: [
        {
          label: t("Website URL"),
          value: form_data?.website,
        },
      ],
    },
    {
      title: t("Plan"),
      action: {
        label: t("Change plan"),
        onclick: () => {
          setStep(0);
          setMobileStep(1);
        },
      },
      items: [
        {
          type: !!change_plan_mode ? "change_plan_preview" : null,
          label: t("Type"),
          value: !!change_plan_mode
            ? {
                current_plan: current_plan?.name,
                selected_plan: selected_plan?.name,
              }
            : selected_plan?.name,
        },
        {
          label: t("Billing cycle"),
          value:
            selected_plan?.contract_period === "monthly"
              ? t("Monthly")
              : t("Yearly"),
        },
        {
          label: t("Amount"),
          value: priceRender(
            ((selected_plan?.price?.amount / 100) * 0.8)?.toFixed(2),
            selected_plan?.price?.currency
          ),
        },
      ],
    },
  ];

  const payment_data = [
    {
      title: t("Contact data"),
      action: {
        label: t("Edit"),
        onclick: () => setStep(1),
      },
      items: [
        {
          label: t("Full name"),
          value: `${form_data?.first_name} ${form_data?.last_name}`,
        },
        {
          label: t("Email"),
          value: form_data?.email,
        },
        {
          label: t("Phone"),
          value: `${form_data?.phone?.code} ${form_data?.phone?.number}`,
        },
      ],
    },
    {
      title: t("Billing data"),
      action: {
        label: t("Edit"),
        onclick: () => setStep(1),
      },
      items: [
        {
          label: t("Name"),
          value: form_data?.billing?.name,
        },
        {
          label: t("Tax identification number"),
          value: form_data?.billing?.tax?.number,
        },
        {
          label: t("Address"),
          value: (
            <>
              {form_data?.billing?.street}
              <br />
              {form_data?.billing?.post_code} {form_data?.billing?.city}
              <br />
              {t(getCountryFromCode(form_data?.billing?.country)?.name)}
            </>
          ),
        },
      ],
    },
  ];

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <div className={`${mainClass}__form form`}>
          <div className={`${mainClass}__form__header`}>
            <h3>{t("Check your data")}</h3>
          </div>
          {details_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
          <div className={`${mainClass}__form__able-to-change-info`}>
            <small>
              {t(
                `You will always be able to change the above data in the administration panel after successfully purchasing a plan for your website`
              )}
            </small>
          </div>
        </div>
        <div className={`${mainClass}__form form`}>
          <div className={`${mainClass}__form__header`}></div>
          {payment_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
      </div>
      {isMobile ? (
        <div className={`${mainClass}__bottom--mobile`}>
          <Button
            type="button"
            variant="transparent"
            onClick={() => prevStep()}
          >
            {t("Back")}
          </Button>
          <Button onClick={onSubmit}>{t("Payment")}</Button>
        </div>
      ) : (
        <div className={`${mainClass}__bottom`}>
          <Button variant="transparent" onClick={() => prevStep()}>
            {t("Previous step")}
          </Button>
          <Button
            onClick={() => {
              onSubmit();
              window.gtag("event", "payment", {
                click_text: t("Payment"),
              });
            }}
            loading={loading}
          >
            {t("Payment")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CheckYourData;
