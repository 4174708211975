import "./styles.scss";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

const mainClass = "progress-bar-steps";

const ProgressBarSteps = ({ data, step, setStep, step_touched }) => {
  const { t } = useTranslation();

  return (
    <div className={mainClass}>
      <span className={`${mainClass}__steps`}>
        {data?.map((item, index) => (
          <div
            key={index}
            onClick={() => (step_touched >= index ? setStep(index) : {})}
            className={classnames(`${mainClass}__item`, {
              [`${mainClass}__item--active`]: step >= index,
              [`${mainClass}__item--finished`]: step > index,
              [`${mainClass}__item--touched`]: step_touched >= index,
            })}
          >
            <small>{item}</small>
          </div>
        ))}
      </span>
      <span className={`${mainClass}__info`}>{`${t("Step")} ${step + 1}/${
        data?.length
      }`}</span>
    </div>
  );
};

export default ProgressBarSteps;
