import { useEffect } from "react";

const useTaskOnboardingInit = () => {
  useEffect(() => {
    const pointer_element = document.getElementsByClassName(
      "task-onboarding__tooltip"
    )?.[0];

    const highlight_element =
      document.getElementsByClassName("onboarding_button")?.[0];
    highlight_element.style.zIndex = 1000;

    const hightlight_position_x = highlight_element?.offsetLeft;
    const hightlight_position_y = highlight_element?.offsetTop;
    const hightlight_height = highlight_element?.offsetHeight;
    const hightlight_width = highlight_element?.offsetWidth;

    const pointer_position_top = hightlight_position_y + hightlight_height - 12;
    const pointer_position_left =
      hightlight_position_x +
      hightlight_width / 2 -
      pointer_element?.offsetWidth / 2;

    pointer_element.style.top = `${pointer_position_top}px`;
    pointer_element.style.left = `${pointer_position_left}px`;
    // eslint-disable-next-line
  }, []);

  return null;
};

export default useTaskOnboardingInit;
