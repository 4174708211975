import "./styles.scss";

import { useTranslation } from "react-i18next";

import Table from "components/Table";
import PillGrid from "components/PillGrid";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import website_status_data from "libs/website_status_data.json";

import { useClients } from "context/admin/clients";

const actions = [
  {
    type: "send_notification",
  },
  {
    type: "create_task",
  },
];

const ClientsContent = () => {
  const { t } = useTranslation();

  const { data, loading, init_loading, filters, filterHandler } = useClients();

  const breadcrumps = [
    {
      label: t("Clients"),
    },
  ];

  console.log(data);

  const pills_data = [
    {
      label: t("Show all"),
      value: "all",
      href: "/clients",
      active: !!!filters?.type,
    },
    ...website_status_data?.map(({ key, label, value }) => ({
      label: t(label),
      value,
      href: `/clients?type=${
        website_status_data?.find((item) => item?.value === value)?.key
      }`,
      active: filters?.type === key,
    })),
  ];

  const table_data = {
    keys: [
      {
        sort_key: "website",
        key: "website-full",
        value: t("Website"),
      },
      {
        key: "tasks",
        value: t("Tasks"),
      },
      {
        key: "status",
        value: t("Status"),
      },
      {
        sort_key: "subscription",
        key: "subscription_plan",
        value: t("Subscription plan"),
      },
    ],
    items: data?.map(({ _id, website, tasks, status, subscription }) => [
      {
        key: "website-full",
        type: "link",
        value: {
          label: website,
          href: `/clients/${_id}`,
        },
      },
      {
        key: "tasks",
        value: tasks,
      },
      {
        key: "status",
        type: "website_status",
        value: status,
      },
      {
        key: "subscription_plan",
        value: subscription?.[0]?.name,
      },
    ]),
  };

  return (
    <DashboardContentLayout
      header_label={t("Clients")}
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <Table
        data={table_data}
        loading={!!loading}
        header={
          <PillGrid header={t("Task status")} items={pills_data} size="small" />
        }
        sort={filters?.sort}
        setSort={(value) => filterHandler("sort", value)}
      />
    </DashboardContentLayout>
  );
};

export default ClientsContent;
